import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store";
import GenericLogin from "@/views/GenericLogin.vue"
import NotFound from "@/views/NotFound.vue";
import Redeem from "@/views/Redeem.vue";
import Search from "@/views/Search.vue";
import Details from "@/views/Details.vue";
import CompanyLogin from "@/views/CompanyLogin.vue";
import PinReset from "@/views/PinReset.vue";
import CompletePinReset from "@/views/CompletePinReset.vue";
import ChooseLocation from "@/views/ChooseLocation.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'GenericLogin',
        component: GenericLogin,
        beforeEnter: (to, from) => {

            if(store.getters['user/isLoggedIn']){
                return { name: 'Redeem', params: { companyId:  store.state.company.id } };
            }

            if(store.state.company && store.state.company.id && store.state.allowGenericHomeRedirect){
                return { name: 'CompanyLogin', params: { companyId: store.state.company.id } };
            }
        },
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/dist/redeem-spa',
        redirect: { path: '/' }
    },
    {
        path: '/:companyId',
        name: 'CompanyLogin',
        props: route => ({ companyIdRoute: route.params.companyId }),
        component: CompanyLogin,
        beforeEnter: async (to, from) => {
            // @ts-ignore
            let targetCompanyId = to.params.companyId.toLowerCase();
            let loggedInCompanyId = store.state.company.id ? store.state.company.id.toLowerCase() : '';

            if(store.getters['user/isLoggedIn'] && targetCompanyId == loggedInCompanyId){
                return { name: 'Redeem', params: { companyId:  store.state.company.id } };
            }
        },
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/choose-location',
        name: 'ChooseLocation',
        component: ChooseLocation,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:companyId/redeem',
        name: 'Redeem',
        props: true,
        component: Redeem,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:companyId/redeem/search',
        name: 'Search',
        props: route => ({ q: route.query.query }),
        component: Search,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/:companyId/redeem/:id',
        name: 'Details',
        props: true,
        component: Details,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pin-reset',
        name: 'PinReset',
        props: route => ({ email: route.query.email }),
        component: PinReset
    },
    {
        path: '/enter-new-pin',
        name: 'CompletePinReset',
        props: route => ({ token: route.query.token, userId: route.query.userId, companyId: route.query.companyId }),
        component: CompletePinReset
    },
    {
        path: '/:catchAll(.*)',
        name: "PageNotFound",
        component: NotFound
    }
];

const router = createRouter({
                                history: createWebHistory(),
                                routes,
                                scrollBehavior(to, from, savedPosition) {
                                    // always scroll to top
                                    return { top: 0 }
                                }
                            });

router.beforeEach(async (to, from) => {
    store.commit("setLastNavigationRoute", null);

    if (to.meta.requiresAuth && to.name != 'ChooseLocation') {
        store.commit("setLastNavigationRoute", to);

        if (!store.getters['user/isLoggedIn']) {

            if(store.state.company && store.state.company.id && !store.state.allowGenericHomeRedirect){
                return { name: 'CompanyLogin', params: { companyId: store.state.company.id } };
            }

            return { name: 'GenericLogin' };
        }

        if (store.getters['user/mustChooseLocation']) {
            return { name: 'ChooseLocation' };
        }
    }
});

router.afterEach((to, from, failure) => {
    document.title = to.meta.title ? to.meta.title as string : `Redeem a ${store.state.company.singularNomenclature?.toLowerCase() ?? "gift card"}`;

    if (!failure) {
        store.commit("setIntercepted401Action", null);
    }
});

export default router
