import { BaseApi } from "@/api/baseApi";

export default class SearchApi extends BaseApi {
    static async search(query: string): Promise<SearchResponse> {
        return this.get(`search/${encodeURIComponent(query.trim())}`);
    }
}

export interface SearchResponse {
    hasMore: boolean;
    giftCards: Array<SearchResult>;
}

export interface SearchResult {
    companyId: string;
    voidedOn: string;
    code: string;
    createdOn: string;
    title: string;
    expiresOn: string;
    validFrom: string;
    message: string;
    recipientName: string;
    recipientEmail: string;
    artworkUrl: string;
    redeemedOn: string;
    remainingValue: number;
    initialValue: number;
    remainingUnits: number;
    initialUnits: number;
    backingType: string;
    currency: string;
    id: string;
    orderMode: string;
    canBeRedeemed: boolean;
    hasExpired: boolean;
    notYetValid: boolean;
    isVoided: boolean;
    isRedeemed: boolean;
}
