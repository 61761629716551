import {HostingEnv, HostingEnvironmentFactory} from "@/HostingEnvironmentFactory";
import Helpers, {FetchRejectReason} from "@/Helpers";

export class BaseApi {
    private static store: any;

    static setStore(store) {
        BaseApi.store = store;
    }

    static get domain() {
        return HostingEnvironmentFactory.get().apiUrl;
    }

    static get credentials() {
        return HostingEnvironmentFactory.get().env == HostingEnv.Development ? 'include' : 'same-origin';
    }

    static handleUnauthorized() {
        console.log("API Unauthorized");
        BaseApi.store.dispatch("user/expired");
    }

    static getRaw(url: string): Promise<Response> {
        const req = new Request(`${this.domain}/${url}`, {
            method: "GET",
            credentials: this.credentials,
            headers: new Headers({"Accepts": "application/json"})
        });

        return fetch(req);
    }

    static get<TResponse>(url: string): Promise<TResponse> {
        return new Promise<TResponse>((resolve, reject) => {
            let rejectReason: FetchRejectReason = {
                responseStatus: -1,
                responseContent: "",
                errorMessage: ""
            };

            this.getRaw(url)
                .then((response) => {
                    Helpers.clearNotifications("fetchfailure");

                    BaseApi.store.dispatch("setServerDate", response.headers.get("date"));

                    rejectReason.responseStatus = response.status;

                    if (!response.ok) {
                        if (response.status == 401
                            || response.headers.get('x-giftup-unauthorized') == "true") {
                            this.handleUnauthorized();
                            rejectReason.responseStatus = 401;
                        }

                        response.text()
                            .then(body => {

                                if(response.status == 400
                                    && body == "Error getting token from cloudbeds"){
                                    BaseApi.store.dispatch("cloudbeds/expiredToken");
                                }

                                rejectReason.responseContent = body;
                                rejectReason.errorMessage = body;
                                reject(rejectReason);
                            });

                        return;
                    }

                    let overrideResponseCode = response.headers.get('x-giftup-response-code');

                    if (overrideResponseCode && overrideResponseCode == "404"){
                        rejectReason.responseStatus = 404;

                        reject(rejectReason);

                        return;
                    }

                    response.json()
                            .then(json => {
                                const model = json as TResponse;

                                resolve(model);
                            });
                }, e => {
                    Helpers.clearNotifications("fetchfailure");

                    Helpers.showNotification({
                        text: "Could not connect to Gift Up, please try again",
                        type: "error",
                        timeout: 7000,
                        queue: "fetchfailure"
                    });

                    rejectReason.responseStatus = 0;
                    rejectReason.errorMessage = e;

                    reject(rejectReason);
                })
                .catch(e => {
                    rejectReason.errorMessage = e;
                    reject(rejectReason);
                });
        });
    }

    static postRaw(url: string, body: object): Promise<Response> {
        const req = new Request(`${this.domain}/${url}`, {
            method: "POST",
            body: JSON.stringify(body),
            credentials: this.credentials,
            headers: new Headers({"Content-Type": "application/json", "Accepts": "application/json"})
        });

        return fetch(req);
    }

    static post<TResponse>(url: string, body: object = {}): Promise<TResponse> {
        return new Promise<TResponse>((resolve, reject) => {
            let rejectReason: FetchRejectReason = {
                responseStatus: -1,
                responseContent: "",
                errorMessage: ""
            };

            this.postRaw(url, body)
                .then((response) => {
                    Helpers.clearNotifications("fetchfailure");

                    BaseApi.store.dispatch("setServerDate", response.headers.get("date"));

                    rejectReason.responseStatus = response.status;

                    let contentType = response.headers.get('content-type');
                    const isJsonResponse = contentType && contentType.indexOf('application/json') >= 0;

                    if (!response.ok) {
                        if (response.status == 401) {
                            this.handleUnauthorized();
                            rejectReason.responseStatus = 401;
                        }

                        if (isJsonResponse) {
                            response.json().then(json => {
                                rejectReason.responseContent = json;
                            }).finally(() => {
                                reject(rejectReason)
                            });
                        }
                        else {
                            response.text().then(text => {
                                rejectReason.responseContent = text;
                            }).finally(() => {
                                reject(rejectReason)
                            });
                        }

                        return;
                    }

                    if (isJsonResponse) {
                        response.json()
                                .then(json => {
                                    const model = json as TResponse;

                                    resolve(model);
                                });
                    }
                    else {
                        response.text()
                                .then(text => {
                                    resolve(text as unknown as TResponse);
                                });
                    }
                }, e => {
                    Helpers.clearNotifications("fetchfailure");

                    Helpers.showNotification({
                        text: "Could not connect to Gift Up, please try again",
                        type: "error",
                        timeout: 7000,
                        queue: "fetchfailure"
                    });

                    rejectReason.responseStatus = 0;
                    rejectReason.errorMessage = e;

                    reject(rejectReason);
                })
                .catch(e => {
                    rejectReason.errorMessage = e;
                    reject(rejectReason);
                });
        });
    }
}

